.up_img {
  height: 400px;
  object-fit: fill;
  width: 400px;
}
.up_img1 {
  height: 400px;
  object-fit: fill;
  width: 100%;
}
.zoom-effect {
  overflow: hidden;
  position: relative;
}

.zoom-effect img {
  transition: transform 0.5s ease;
}

.zoom-effect:hover img {
  transform: scale(1.2); /* Adjust the scale factor as needed */
}
.link_btn {
  text-decoration: none;
}
.media Link {
  text-decoration: none;
}
