.text {
  font-size: 18px;
  background-color: rgb(16, 16, 19);
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown:hover .submenu {
  display: none;
}
.dropdown-menu .mainmenu:hover .submenu {
  display: block;
}
.dropdown:hover .submenu_1 {
  display: none;
}
.mainmenu_1:hover .submenu_1 {
  display: block;
}
.link_font {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.dropdown-menu a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-menu a:hover {
  background-color: skyblue;
}

.dropdown-item:hover .doubledoor-dropdown {
  display: block;
}
.dropdown-menu a {
  padding: 7px;
}
@media all and (min-width: 992px) {
  .dropdown-menu li {
    position: relative;
  }
  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 18px;
  }
  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }
  .nav-item .submenu_1 {
    display: none;
    position: absolute;
    left: 100%;
    top: 18px;
  }
  .nav-item .submenu_1-left {
    right: 100%;
    left: auto;
  }
  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }
}
@media (max-width: 991px) {
  .dropdown-menu .dropdown-menu {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    margin-bottom: 0.5rem;
  }
}
