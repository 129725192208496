.containers {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ones {
  border: 1px solid black;
}
.special_font {
  font-variant: small-caps;
  font-size: 19px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.special_font_head {
  font-variant: small-caps;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.colour {
  background-color: gainsboro;
}
.specialfont_para {
  font-variant: small-caps;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.img-colour {
  object-fit: fill;
  width: 100%;
  height: 500px;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: lightblue;
  border-radius: 7px;
}
.icons1 {
  font-size: 50px;
}
.icons2 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.animate {
  transition: transform 0.4s ease;
}

.animate:hover {
  transform: translateY(-30px); /* Adjust the value as needed */
}
.our_font {
  font-variant: small-caps;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.para_2 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.font {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
