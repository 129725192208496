.img_des {
  width: 100%;
  height: 550px;
  object-fit: fill;
}
@keyframes bounceInLeft {
  from {
    opacity: 0;
    transform: translateX(-500px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
.animate__bounceInLeft {
  animation-name: bounceInLeft;
  animation-duration: 2s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out; /* Adjust the timing function */
}
.horizontal-card {
  border: none;
}

.card-img {
  width: 100%;
  height: auto;
}
.heads_font {
  font-variant: small-caps;
  color: blue;
}
