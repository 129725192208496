.upvc_img {
  object-fit: fill;
  height: 500px;
  width: 100%;
}
.upvc_font {
  font-variant: small-caps;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
@keyframes animate_bounce {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
.animate_right {
  animation: animate_bounce 2s ease-in-out;
}
