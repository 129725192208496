.bg_footer {
  background-color: #313234;
}
.footer_font {
  font-variant: small-caps;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 22px;
}
.footer_head {
  color: white;
}
.footer_text {
  /* font-variant: small-caps; */
  font-size: 17px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.insta {
  color: palevioletred;
}
.fa-facebook {
  color: blue;
}
.fa-whatsapp {
  color: green;
}
.color {
  color: rgb(231, 46, 119);
}
.map {
  width: 650px;
  height: 450px;
  border: 0;
}
.icons {
  font-size: 23px;
}
.connection_link {
  text-decoration: none;
  color: white;
}
