.logo_img {
  height: 150px;
  width: 150px;
}
.box_shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.map {
  width: 100%;
  height: 350px;
}
.contact_bg {
  background-color: rgb(235, 231, 231);
}
.footer-link {
  text-decoration: none;
}
.link_style {
  text-decoration: none;
}
.whatsapp-icon {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 999;
  animation: rotateIcon 2s linear infinite;
}
.whatsapp-icon .icons {
  font-size: 54px; /* Adjust the font size as needed */
}
@keyframes rotateIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
