.img {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../image/Featured\ -\ 1620\ x\ 1080\ \(2\).webp");
  height: 500px;
  background-size: cover;
  position: relative;
}

/* .img:hover {
  opacity: 1;
} */
.img h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Times New Roman", Times, serif;
  font-size: 65px;
}
.special_font {
  font-variant: small-caps;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
s .one {
  border: 1px solid black;
}
.bg_colour {
  background-color: gainsboro;
}
.heading_colour {
  color: #132048;
  font-weight: 700;
}
.para_font {
  font-size: 17px;
  font-variant: small-caps;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.home_head {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
}
.head_font {
  /* font-family: inherit; */
  font-weight: 200;
  /* line-height: 1.2; */
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-style: normal;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.card-img-top {
  height: 250px;
}
.card-text {
  font-family: inherit;
  /* letter-spacing: 0.4px; */
  font-weight: 400;
}

/* Card container */
.card {
  perspective: 1000px;
  border: 1px solid #ddd;

  height: 420px;
}
.san {
  border: 1px solid black;
}
/* Card inner container */
.card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Flip the card on hover */
#casementWindowCard:hover .card-inner {
  transform: rotateY(180deg);
}

/* Card front and back styling */
.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border: 1px solid transparent;
  box-sizing: border-box;
}

/* Card front styling */
.card-front {
  z-index: 2;
}

/* Card back styling */
.card-back {
  transform: rotateY(180deg);
  /* Add styling for the back of the card here */
}

/* Additional content styling */
.card-back {
  /* Add styling for the back of the card here */
  background-color: black;
  color: white;
  border-radius: 5px;
}
.img-fluid {
  object-fit: cover;
}
.para_1 {
  line-height: 30px;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.percent {
  color: black transparents;
  font-family: Termina, "sans-serif";
  font-size: 100px;
  -webkit-text-stroke: 1px #fff;
  font-weight: 700;
}
.head {
  font-family: Termina, "sans-serif";
  font-weight: 600;
  font-size: 30px;
}
.bg_3 {
  background-color: gainsboro;
}
.normal_para {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.card-title {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
@media only screen and (max-width: 600px) {
  .media_head h2 {
    font-size: 40px;
  }
  .back_font p {
    font-size: 15px;
  }
  .back_font h2 {
    font-size: 25px;
  }
  .back_font h1 {
    font-size: 60px;
  }
  .back_font ul li {
    font-size: 15px;
  }
  .media_head h1 {
    font-size: 25px;
  }
}
